import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { CheckmarkIconSmall } from '@liveauctioneers/hammer-ui-core/icons/checkmarkIcon';
import { CompleteAccountModalContext, ModalContext, openConfirmUnfollowSearchModal } from '@/redux/modules/modal';
import {
    createSaveSearchParams,
    getIsSavedSearch,
    getSavedSearchId,
    getSaveSearchSubmitted,
    submitSaveSearch,
} from '@/redux/modules/saveSearch';
import { EventContext } from '@/redux/modules/analytics';
import { HeartIconSmall } from '@liveauctioneers/hammer-ui-core/icons/heartIcon';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';

type Props = {
    categoryId: string;
    className?: string;
    onClick: () => void;
    saveSearchKey: string;
};

const modalContext: ModalContext = {
    eventContext: EventContext.followSearch,
    id: 0,
    type: CompleteAccountModalContext.FollowSearch,
};

const FollowSearchButton = ({ categoryId, className, onClick, saveSearchKey }: Props) => {
    const dispatch = useAppDispatch();
    const isSavedSearch = useAppSelector((state) => getIsSavedSearch(state, saveSearchKey));
    const savedSearchId = useAppSelector((state) => getSavedSearchId(state, saveSearchKey));
    const submitted = useAppSelector(getSaveSearchSubmitted);
    const { formatMessage } = useIntl();

    const addSaveSearch = React.useCallback(() => {
        dispatch(submitSaveSearch(createSaveSearchParams(categoryId), true));
    }, [categoryId, dispatch]);

    const unfollowSearch = React.useCallback(() => {
        dispatch(openConfirmUnfollowSearchModal(savedSearchId));
    }, [dispatch, savedSearchId]);

    const callback = React.useMemo(
        () => (isSavedSearch ? unfollowSearch : addSaveSearch),
        [unfollowSearch, isSavedSearch, addSaveSearch]
    );

    return (
        <ShouldLogInContainer
            modalContext={modalContext}
            runFunction={callback}
        >
            <Button
                className={className}
                data-testid="saveSearchButton"
                iconLeft={isSavedSearch ? CheckmarkIconSmall : HeartIconSmall}
                loading={submitted}
                onClick={onClick}
                variant={isSavedSearch ? 'outlined' : 'filled'}
            >
                {formatMessage({
                    id: isSavedSearch ? 'following_search' : 'follow_this_search',
                })}
            </Button>
        </ShouldLogInContainer>
    );
};

export default FollowSearchButton;
