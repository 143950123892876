import { createSaveSearchKey, getHasSavedSearches, getSaveSearchState } from '@/redux/modules/saveSearch';
import { fetchSavedSearchesIfNeeded } from '@/redux/modules/savedSearch';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import items from './options';
import SearchFollowSuggestionsCard from './SearchFollowSuggestionsCard';
import SeeAllLink from '../SeeAllLink/SeeAllLink';
import SideScroller from '@/components/SideScroller/SideScroller';
import styled from 'styled-components';

type Props = {
    className?: string;
    useAltHeader?: boolean;
};

const SearchFollowSuggestions = ({ className, useAltHeader }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const { byParams } = useAppSelector(getSaveSearchState);
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const hasSavedSearches = useAppSelector(getHasSavedSearches);
    const [startedWithSavedSearches] = useState(hasSavedSearches);

    useEffect(() => {
        dispatch(fetchSavedSearchesIfNeeded());
    }, [dispatch, isLoggedIn]);

    const carouselCards = useMemo(
        () =>
            items?.map((item, index) => {
                const saveSearchParams = createSaveSearchKey(item.categoryId);
                const relevantSaveSearch = byParams[saveSearchParams];
                return {
                    card: (
                        <SearchFollowSuggestionsCard
                            categoryId={item.categoryId}
                            categoryUrl={item.categoryUrl}
                            followerCount={item.followerCount}
                            images={item.images}
                            itemCount={item.itemCount}
                            keyword={item.keyword}
                            name={item.name}
                            saveSearchStateKey={relevantSaveSearch?.key ?? ''}
                        />
                    ),
                    key: `${index}-${item.categoryId}`,
                };
            }),
        [byParams]
    );

    if (startedWithSavedSearches) {
        return null;
    }

    return (
        <StyledSearchFollowSuggestions
            className={className}
            id="followedSearch"
        >
            {useAltHeader ? (
                <StyledAltHeader>
                    <FormattedMessage id="searchFollowSuggestions.altHeader" />
                </StyledAltHeader>
            ) : (
                <>
                    <div className="mb-2xs flex">
                        <StyledTitle>
                            <H2>
                                <FormattedMessage id="searchFollowSuggestions.header" />
                            </H2>
                            <SeeAllLink
                                text={formatMessage({
                                    id: 'searchFollowSuggestions.manage',
                                })}
                                url="/dashboard/followed-searches"
                            />
                        </StyledTitle>
                    </div>
                    <StyledSubtitle>
                        <FormattedMessage id="searchFollowSuggestions.subTitle" />
                    </StyledSubtitle>
                </>
            )}
            <StyledCarousel
                cards={carouselCards}
                useOverlayControls
            />
        </StyledSearchFollowSuggestions>
    );
};

export default memo<Props>(SearchFollowSuggestions);

const StyledSearchFollowSuggestions = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTitle = styled.div`
    flex: 1 1 auto;
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-left: 20px;
    }

    h2 {
        margin: 0;
        padding: 0;
        display: inline-block;
        margin-right: 15px;
    }
`;

const StyledAltHeader = styled.div`
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
    margin: 0 20px 20px;
    max-width: 1200px;
    align-self: center;
    width: 100%;
`;

const StyledSubtitle = styled.div`
    margin-bottom: 20px;
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    font-size: 14px;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.colors.grey100};
    line-height: 22px;
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-left: 20px;
    }
`;

const StyledCarousel = styled(SideScroller)`
    max-width: 1200px;
`;
