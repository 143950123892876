export default [
    {
        categoryId: '4',
        categoryUrl: 'furniture',
        followerCount: 125,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20190107_lh.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20180705_antiquefurniture.jpg',
            'https://images.liveauctioneers.com/static/mail/images/featured/20181129_bruunfurniture.jpg',
        ],
        itemCount: 8147,
        keyword: '',
        name: 'Furniture',
    },
    {
        categoryId: '10',
        categoryUrl: 'paintings',
        followerCount: 416,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20190228_paintings.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/oilpaintings.jpg',
            'https://images.liveauctioneers.com/static/mail/images/featured/20181129_lhprints.jpg',
        ],
        itemCount: 9158,
        keyword: '',
        name: 'Paintings',
    },
    {
        categoryId: '24',
        categoryUrl: 'coins-currency-and-stamps',
        followerCount: 46,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20190307_coins.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/stamps.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/CoinsCurrencyStamps_Image3.jpg',
        ],
        itemCount: 7234,
        keyword: '',
        name: 'Coins, Currency, & Stamps',
    },
    {
        categoryId: '5',
        categoryUrl: 'home-and-decor',
        followerCount: 360,
        images: [
            'https://images.liveauctioneers.com/static/suggested-searches/HomeandGarden_Image1.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/HomeandGarden_Image2.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/HomeandGarden_Image3.jpg',
        ],
        itemCount: 38468,
        keyword: '',
        name: 'Home & Décor',
    },
    {
        categoryId: '3',
        categoryUrl: 'fashion',
        followerCount: 205,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/accessories.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20181129_ktfashion.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20190221_fashion.jpg',
        ],
        itemCount: 4110,
        keyword: '',
        name: 'Fashion',
    },
    {
        categoryId: '162',
        categoryUrl: 'chinese',
        followerCount: 22,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20180802_chinese-porcelain.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20180830_chinesefurniture.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20181227_jade.jpg',
        ],
        itemCount: 13505,
        keyword: '',
        name: 'Chinese Antiques',
    },
    {
        categoryId: '11',
        categoryUrl: 'photography',
        followerCount: 33,
        images: [
            'https://images.liveauctioneers.com/static/suggested-searches/Photography_Image1.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/Photography_Image2.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/Photography_Image3.jpg',
        ],
        itemCount: 1697,
        keyword: '',
        name: 'Photography',
    },
    {
        categoryId: '97',
        categoryUrl: 'watches',
        followerCount: 75,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20190110_watches.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20190103_pocketwatches_01.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20180628_watches.jpg',
        ],
        itemCount: 4379,
        keyword: '',
        name: 'Watches',
    },
    {
        categoryId: '96',
        categoryUrl: 'rings',
        followerCount: 49,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20181213_rings.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/emeraldrings.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/Rings_Image3.jpg',
        ],
        itemCount: 32103,
        keyword: '',
        name: 'Rings',
    },
    {
        categoryId: '10,25830',
        categoryUrl: 'paintings/oil',
        followerCount: 36,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20180802_oil-paintings.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20181220_oilpaintings.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/oilpaintings.jpg',
        ],
        itemCount: 2527,
        keyword: '',
        name: 'Oil Paintings ',
    },
    {
        categoryId: '46',
        categoryUrl: 'toys-trains-games',
        followerCount: 44,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20190117_toys.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20190307_toys.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/dolls.jpg',
        ],
        itemCount: 2839,
        keyword: '',
        name: 'Toys, Dolls, & Games',
    },
    {
        categoryId: '74',
        categoryUrl: 'clocks',
        followerCount: 12,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20181206_clocks.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20180705_clocks.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20181227_clocks.jpg',
        ],
        itemCount: 738,
        keyword: '',
        name: 'Clocks',
    },
    {
        categoryId: '163',
        categoryUrl: 'japanese',
        followerCount: 10,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/japanesevases.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/JapaneseAntiques_Image2.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/JapaneseAntiques_Image3.jpg',
        ],
        itemCount: 1086,
        keyword: '',
        name: 'Japanese Antiques',
    },
    {
        categoryId: '85',
        categoryUrl: 'rugs-carpets',
        followerCount: 37,
        images: [
            'https://images.liveauctioneers.com/static/mail/images/features/20180705_rugs.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/persianrugs.jpg',
            'https://images.liveauctioneers.com/static/mail/images/features/20190124_rugs.jpg',
        ],
        itemCount: 3006,
        keyword: '',
        name: 'Rugs & Carpets',
    },
    {
        categoryId: '12',
        categoryUrl: 'prints-multiples',
        followerCount: 78,
        images: [
            'https://images.liveauctioneers.com/static/suggested-searches/Prints&Multiples_Image1.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/Prints&Multiples_Image2.jpg',
            'https://images.liveauctioneers.com/static/suggested-searches/Prints&Multiples_Image3.jpg',
        ],
        itemCount: 8971,
        keyword: '',
        name: 'Prints & Multiples',
    },
];
