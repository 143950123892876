import * as React from 'react';
import { addImageParams, getCategoryPageUrl } from '@/utils/urls';
import { fetchSavedSearchItemsIfNeeded } from '@/redux/modules/savedSearch';
import { FormattedMessage, FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { trackFromSearchFollowSuggestionClick } from '@/redux/modules/analytics';
import { useAppDispatch } from '@/redux/hooks';
import FollowSearchButton from './FollowSearchButton';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';

type Props = {
    categoryId: string;
    categoryUrl: string;
    followerCount: number;
    images: string[];
    itemCount: number;
    keyword: string;
    name: string;
    saveSearchStateKey: string;
};

const SearchFollowSuggestionsCard = ({
    categoryId,
    categoryUrl,
    followerCount,
    images,
    itemCount,
    keyword,
    name,
    saveSearchStateKey,
}: Props) => {
    const dispatch = useAppDispatch();

    const trackClick = React.useCallback(() => {
        dispatch(trackFromSearchFollowSuggestionClick({ categoryId, keyword }));
        dispatch(fetchSavedSearchItemsIfNeeded(true));
    }, [categoryId, dispatch, keyword]);

    return (
        <StyledSearchFollowSuggestionsCard>
            <Link
                route
                to={`${getCategoryPageUrl(categoryUrl, categoryId)}`}
            >
                <StyledImagesContainer>
                    {images.map((imageUrl, index) => (
                        <StyledImageWrapper key={`thumbnail-${index}`}>
                            <StyledImage
                                alt={`${name} - ${index + 1} - image`}
                                loading="lazy"
                                src={addImageParams({
                                    url: imageUrl,
                                    width: 188,
                                })}
                            />
                        </StyledImageWrapper>
                    ))}
                </StyledImagesContainer>
                <StyledDetails>
                    <StyledCategory>{name}</StyledCategory>
                    <StyledStats>
                        <FormattedNumber
                            key="search-follow-suggestions-card-item-count"
                            style="decimal"
                            value={itemCount}
                        />{' '}
                        <FormattedMessage id="searchFollowSuggestions.items" />{' '}
                        <FormattedNumber
                            key="search-follow-suggestions-card-follower-count"
                            style="decimal"
                            value={followerCount}
                        />{' '}
                        <FormattedMessage id="searchFollowSuggestions.followers" />
                    </StyledStats>
                </StyledDetails>
            </Link>
            <StyledFollow>
                <FollowSearchButton
                    categoryId={categoryId}
                    onClick={trackClick}
                    saveSearchKey={saveSearchStateKey}
                />
            </StyledFollow>
        </StyledSearchFollowSuggestionsCard>
    );
};

export default SearchFollowSuggestionsCard;

const StyledSearchFollowSuggestionsCard = styled.div`
    border: solid 1px ${({ theme }) => theme.colors.grey400};
    height: 215px;
    width: 285px;
    min-width: 285px;

    a {
        text-decoration: none;
    }
`;

const StyledImagesContainer = styled.div`
    border-bottom: solid 1px ${({ theme }) => theme.colors.grey400};
    background: white;
    display: flex;
`;

const StyledImageWrapper = styled.div`
    height: 72px;
    width: 94px;
    background: #e0dad3; /* This is the background used in the images - they need updated or this does */
    margin-right: 1px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &:last-child {
        margin-right: 0px;
    }
`;

const StyledImage = styled.img`
    max-width: calc(100% + 16px);
    max-height: calc(100% + 16px);
`;

const StyledDetails = styled.div`
    margin-top: 15px;
    margin-left: 13px;
    margin-right: 13px;
`;

const StyledCategory = styled.div`
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.black};
`;

const StyledStats = styled.div`
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    color: ${({ theme }) => theme.colors.grey100};
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    margin-top: 6px;
`;

const StyledFollow = styled.div`
    margin-top: 15px;
    margin-left: 13px;

    button {
        width: 258px;
    }

    button[class*='button-saved'] {
        cursor: initial;
    }
`;
