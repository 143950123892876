import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { CaretRight } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';

type Props = {
    external?: boolean;
    text?: any;
    url: string;
};

const SeeAllLink = ({ external = false, text = '', url = '' }) => {
    const externalProps = external
        ? {
              rel: 'noopener',
              target: '_blank',
          }
        : {};

    return (
        <StyledSeeAllLink
            {...externalProps}
            className="group"
            route={!external}
            to={url || '#'}
        >
            <BodySecondary color="secondary">
                {text || (
                    <FormattedMessage
                        id="see_all"
                        key="see_all"
                    />
                )}
            </BodySecondary>
            <CaretRight
                className="inline-block text-text-secondary"
                size="xs"
            />
        </StyledSeeAllLink>
    );
};

export default React.memo<Props>(SeeAllLink);

const StyledSeeAllLink = styled(Link)`
    &&&& {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        &:hover {
            svg,
            span {
                color: ${({ theme }) => theme.colors.blue200};
            }

            text-decoration: underline;
        }

        @media (max-width: 550px) {
            margin-left: 10px;
        }
    }
`;
